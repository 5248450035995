import React, { useState, useCallback, useEffect } from "react";
import { jwtDecode } from 'jwt-decode'

export const AuthContext = React.createContext({
  isLoggedIn: false,
  token: null,
  user_id: null,
  role: null,
  username: null,
  team_id: null,
  division: null,
  time_zone: null,
  show_welcome_banner: null,
  isAuthReady: false,
  login: (user_id, token, role, username, team_id, division, time_zone, show_welcome_banner) => {},
  logout: () => {},
  setRole: (role) => {},
  setUsername: (username) => {},
  setTeamId: (team_id) => {},
  setDivision: (division) => {},
  setTimeZone: (time_zone) => {},
  setShowWelcomeBanner: (show_welcome_banner) => {},
});

export const AuthContextProvider = (props) => {
  const [token, setToken] = useState(null);
  const [user_id, setUserId] = useState(null);
  const [role, setRole] = useState(null);
  const [username, setUsername] = useState(null);
  const [team_id, setTeamId] = useState(null);
  const [division, setDivision] = useState(null);
  const [time_zone, setTimeZone] = useState(null);
  const [show_welcome_banner, setShowWelcomeBanner] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setRole(null);
    setUsername(null);
    setTeamId(null);
    setDivision(null);
    setTimeZone(null);
    setShowWelcomeBanner(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    localStorage.removeItem("username");
    localStorage.removeItem("team_id");
    localStorage.removeItem("division");
    localStorage.removeItem("time_zone");
    localStorage.removeItem("show_welcome_banner");
    setIsAuthReady(true);
  }, []);

  const login = useCallback(
    (user_id, token, role, username, team_id, division, time_zone, show_welcome_banner) => {
      setToken(token);
      setUserId(user_id);
      setRole(role);
      setUsername(username);
      setTeamId(team_id);
      setDivision(division);
      setTimeZone(time_zone);
      setShowWelcomeBanner(show_welcome_banner);
      localStorage.setItem("token", token);
      localStorage.setItem("user_id", user_id);
      localStorage.setItem("role", role);
      localStorage.setItem("username", username || "");
      localStorage.setItem("team_id", team_id || "");
      localStorage.setItem("division", division || "");
      localStorage.setItem("time_zone", time_zone || "");
      localStorage.setItem("show_welcome_banner", show_welcome_banner !== null && show_welcome_banner !== undefined ? String(show_welcome_banner) : "");
      setIsAuthReady(true);
    },
    []
  );

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        setIsAuthReady(false);
        
        const storedToken = localStorage.getItem("token");
        const storedUserId = localStorage.getItem("user_id");
        const storedRole = localStorage.getItem("role");
        const storedUsername = localStorage.getItem("username");
        const storedTeamId = localStorage.getItem("team_id");
        const storedDivision = localStorage.getItem("division");
        const storedTimeZone = localStorage.getItem("time_zone");
        const storedShowWelcomeBanner = localStorage.getItem("show_welcome_banner");

        if (storedToken && storedUserId) {
          try {
            const decodedToken = jwtDecode(storedToken);
            const expirationTime = decodedToken.exp * 1000;

            if (expirationTime <= Date.now()) {
              console.log("Token expired");
              logout();
            } else {
              setToken(storedToken);
              setUserId(storedUserId);
              setRole(storedRole || null);
              
              if (storedUsername) setUsername(storedUsername);
              if (storedTeamId) setTeamId(storedTeamId);
              if (storedDivision) setDivision(storedDivision);
              if (storedTimeZone) setTimeZone(storedTimeZone);
              
              if (storedShowWelcomeBanner === "true") {
                setShowWelcomeBanner(true);
              } else if (storedShowWelcomeBanner === "false") {
                setShowWelcomeBanner(false);
              } else {
                setShowWelcomeBanner(null);
              }

              try {
                const response = await fetch(
                  `${process.env.REACT_APP_API_URL}/users/${storedUserId}`,
                  {
                    headers: {
                      Authorization: `Bearer ${storedToken}`,
                    },
                  }
                );
                
                if (response.ok) {
                  const userData = await response.json();
                  
                  setTeamId(userData.team_id || null);
                  setDivision(userData.division || null);
                  setTimeZone(userData.time_zone || null);
                  
                  const welcomeBanner = userData.show_welcome_banner;
                  if (typeof welcomeBanner === 'boolean') {
                    setShowWelcomeBanner(welcomeBanner);
                    localStorage.setItem("show_welcome_banner", String(welcomeBanner));
                  } else {
                    setShowWelcomeBanner(null);
                    localStorage.setItem("show_welcome_banner", "");
                  }
                  
                  localStorage.setItem("username", userData.username || "");
                  localStorage.setItem("team_id", userData.team_id || "");
                  localStorage.setItem("division", userData.division || "");
                  localStorage.setItem("time_zone", userData.time_zone || "");
                } else {
                  console.warn('Could not refresh user data from API, using stored values');
                }
              } catch (error) {
                console.error('Error fetching fresh user data:', error);
              }
            }
          } catch (error) {
            console.error('Error decoding token:', error);
            logout();
          }
        } else {
          console.log("No stored credentials found");
          logout();
        }
      } catch (error) {
        console.error('Unexpected error during auth initialization:', error);
        logout();
      } finally {
        setIsAuthReady(true);
      }
    };

    initializeAuth();
  }, [logout]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token || null,
        user_id: user_id || null,
        role: role || null,
        username: username || null,
        team_id: team_id || null,
        division: division || null,
        time_zone: time_zone || null,
        show_welcome_banner: typeof show_welcome_banner === 'boolean' ? show_welcome_banner : null,
        isAuthReady,
        login,
        logout,
        setRole,
        setUsername,
        setTeamId,
        setDivision,
        setTimeZone,
        setShowWelcomeBanner,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
