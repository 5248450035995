import React, { useContext, useEffect, useState, useMemo } from "react";
import { format, utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
  isToday,
  isYesterday,
  startOfISOWeek,
  startOfDay,
  isAfter,
  isSameDay,
  parseISO,
} from "date-fns";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListSubheader,
  ListItemIcon,
  ListItemText,
  Avatar,
  Badge,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Modal,
  Fade,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Settings, LogOut, Circle, Check } from "lucide-react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AppsIcon from "@mui/icons-material/Apps";
import MailIcon from "@mui/icons-material/Mail";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import GetAppIcon from "@mui/icons-material/GetApp";
import HelpIcon from "@mui/icons-material/Help";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TagIcon from "@mui/icons-material/Tag";
import ChatIcon from "@mui/icons-material/Chat";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import UpdateIcon from "@mui/icons-material/Update";
import CommentIcon from "@mui/icons-material/Comment";
import CampaignIcon from "@mui/icons-material/Campaign";
import {
  QuestionAnswer as FAQIcon,
  GroupWork as ProjectIcon,
  PersonAdd as AddMemberIcon,
  SupervisorAccount as RoleUpdateIcon,
  DeleteForever as ProjectDeleteIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../context/auth-context";
import { NotificationContext } from "../../context/NotificationContext";
import defaultUserImage from "../../../user/components/user.png";
import logo from "./CBLogo.png";

const StyledBadge = styled(Badge)(({ theme, badgeColor, status }) => ({
  position: "relative",
  display: "inline-flex",
  "& .MuiBadge-badge": {
    backgroundColor: badgeColor,
    color: badgeColor,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    ...(status === "Available" && {
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    }),
  },
  ...(status === "Available" && {
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }),
}));

const NotificationsMenu = ({
  anchorEl,
  open,
  onClose,
  notifications,
  onMarkAsRead,
  onMarkAllAsRead,
  auth,
}) => {
  // Add state for active tab (0 = Announcements, 1 = Notifications)
  const [activeTab, setActiveTab] = useState(1); // Default to Notifications tab

  useEffect(() => {
    const userTimeZone =
      localStorage.getItem("time_zone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;
  }, []);

  const handleDownload = async (fileId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/downloads/${fileId}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        },
      );
      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const getNotificationAction = (notification) => {
    if (
      notification.type === "Download Ready" &&
      notification.metadata?.file_id
    ) {
      return (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleDownload(notification.metadata.file_id);
          }}
          sx={{ mr: 1 }}
        >
          <GetAppIcon sx={{ fontSize: 20, color: "#2196f3" }} />
        </IconButton>
      );
    }
    return null;
  };

  const formatDate = (dateString) => {
    try {
      const userTimeZone =
        localStorage.getItem("time_zone") ||
        Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Convert the date string to ISO format
      const [datePart, timePart] = dateString.split(" ");
      const isoString = `${datePart}T${timePart}Z`;

      // Parse and convert to user's timezone
      const utcDate = parseISO(isoString);
      const zonedDate = utcToZonedTime(utcDate, userTimeZone);

      // Format based on whether it's today or not
      if (isToday(zonedDate)) {
        return format(zonedDate, "h:mm a", { timeZone: userTimeZone });
      }

      return format(zonedDate, "MMM d, h:mm a", { timeZone: userTimeZone });
    } catch (error) {
      console.error("Error formatting date:", error, dateString);
      return "Invalid Date";
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case "Download Ready":
        return <GetAppIcon sx={{ color: "#2196f3" }} />;
      case "New Feature":
        return <NewReleasesIcon sx={{ color: "#4caf50" }} />;
      case "General":
        return <AnnouncementIcon sx={{ color: "#ff9800" }} />;
      case "New User Signup":
        return <PersonIcon sx={{ color: "#1976d2" }} />;
      case "#tagged":
        return <TagIcon sx={{ color: "#9c27b0" }} />;
      case "Important":
        return <CampaignIcon sx={{ color: "#f44336" }} />;
      case "Task Assignment":
        return <AssignmentIcon sx={{ color: "#673ab7" }} />;
      case "Task Unassignment":
        return <AssignmentReturnedIcon sx={{ color: "#795548" }} />;
      case "Task Status Update":
        return <UpdateIcon sx={{ color: "#009688" }} />;
      case "Task Comment":
        return <CommentIcon sx={{ color: "#607d8b" }} />;
      case "Project Deletion":
        return <ProjectDeleteIcon sx={{ color: "#d32f2f" }} />;
      case "Project Membership":
        return <AddMemberIcon sx={{ color: "#1976d2" }} />;
      case "Project Role Update":
        return <RoleUpdateIcon sx={{ color: "#7b1fa2" }} />;
      default:
        return <NotificationsIcon sx={{ color: "#757575" }} />;
    }
  };

  // Filter notifications based on tab
  const filteredNotifications = useMemo(() => {
    const announcementTypes = ["New Feature", "General", "Important"];
    const notificationTypes = [
      "Download Ready",
      "New User Signup",
      "#tagged",
      "Task Assignment",
      "Task Unassignment",
      "Task Status Update",
      "Task Comment",
      "Project Deletion",
      "Project Membership",
      "Project Role Update",
    ];

    return notifications.filter((notification) =>
      activeTab === 0
        ? announcementTypes.includes(notification.type)
        : notificationTypes.includes(notification.type),
    );
  }, [notifications, activeTab]);

  // Get counts for badge indicators
  const announcementCount = useMemo(() => {
    return notifications.filter((n) =>
      ["New Feature", "General"].includes(n.type),
    ).length;
  }, [notifications]);

  const notificationCount = useMemo(() => {
    return notifications.filter((n) =>
      [
        "Download Ready",
        "New User Signup",
        "#tagged",
        "Task Assignment",
        "Task Unassignment",
        "Task Status Update",
        "Task Comment",
        "Project Deletion",
        "Project Membership",
        "Project Role Update",
      ].includes(n.type),
    ).length;
  }, [notifications]);

  const groupedNotifications = useMemo(() => {
    const userTimeZone =
      localStorage.getItem("time_zone") ||
      Intl.DateTimeFormat().resolvedOptions().timeZone;

    const groups = {
      today: [],
      yesterday: [],
      thisWeek: [],
      older: [],
    };

    const now = new Date();
    const zonedNow = utcToZonedTime(now, userTimeZone);

    filteredNotifications.forEach((notification) => {
      try {
        const [datePart, timePart] = notification.date.split(" ");
        const isoString = `${datePart}T${timePart}Z`;
        const utcDate = parseISO(isoString);
        const zonedDate = utcToZonedTime(utcDate, userTimeZone);

        const startOfWeek = startOfISOWeek(zonedNow);
        const notificationDate = startOfDay(zonedDate);

        if (isToday(zonedDate)) {
          groups.today.push(notification);
        } else if (isYesterday(zonedDate)) {
          groups.yesterday.push(notification);
        } else if (
          isAfter(notificationDate, startOfWeek) ||
          isSameDay(notificationDate, startOfWeek)
        ) {
          groups.thisWeek.push(notification);
        } else {
          groups.older.push(notification);
        }
      } catch (error) {
        console.error(
          "Error processing notification date:",
          error,
          notification,
        );
        groups.older.push(notification);
      }
    });

    const sortByDate = (a, b) => new Date(b.date) - new Date(a.date);
    groups.today.sort(sortByDate);
    groups.yesterday.sort(sortByDate);
    groups.thisWeek.sort(sortByDate);
    groups.older.sort(sortByDate);

    return groups;
  }, [filteredNotifications]);

  const renderNotificationGroup = (notifications, groupTitle) => {
    if (notifications.length === 0) return null;

    return (
      <>
        <ListSubheader
          sx={{
            bgcolor: "grey.50",
            py: 1,
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 104,
            zIndex: 1,
          }}
        >
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            {groupTitle}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {notifications.length}{" "}
            {notifications.length === 1 ? "notification" : "notifications"}
          </Typography>
        </ListSubheader>
        {notifications.map((notification) => (
          <MenuItem
            key={notification.notification_id}
            sx={{
              px: 2,
              py: 1.5,
              "&:hover": { bgcolor: "grey.50" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "100%",
                alignItems: "flex-start",
              }}
            >
              <Box sx={{ mt: 0.5 }}>
                {getNotificationIcon(notification.type)}
              </Box>

              <Box sx={{ flexGrow: 1, minWidth: 0 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    gap: 1,
                    minWidth: 0,
                  }}
                >
                  <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                    {notification.type}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{ whiteSpace: "nowrap", flexShrink: 0 }}
                  >
                    {formatDate(notification.date)}
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{
                    mt: 0.5,
                    wordBreak: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {notification.message}
                </Typography>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {getNotificationAction(notification)}
                <Tooltip title="Mark as read">
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      onMarkAsRead(notification.notification_id);
                    }}
                    sx={{ "&:hover": { bgcolor: "grey.100" } }}
                  >
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </MenuItem>
        ))}
      </>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: 420, // Increased width to prevent header overlap
          maxHeight: "80vh",
          overflowY: "auto",
          mt: 1,
          borderRadius: 2,
          boxShadow: 3,
          "& .MuiList-root": {
            padding: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 2,
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Box
          sx={{
            px: 2,
            py: 1.5,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Notifications
          </Typography>
          <Tooltip title="Mark all as read">
            <IconButton
              size="small"
              onClick={onMarkAllAsRead}
              sx={{ "&:hover": { bgcolor: "grey.100" } }}
            >
              <MailIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        </Box>

        {/* New Tab Navigation */}
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            minHeight: "42px",
            "& .MuiTab-root": {
              minHeight: "42px",
              p: 0,
              mx: 1,
              fontSize: "0.75rem",
              position: "relative",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "#1976d2",
              height: 3,
            },
          }}
        >
          <Tab
            label={
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative",
                }}
              >
                <AnnouncementIcon
                  sx={{
                    fontSize: 18,
                    mr: 0.5,
                    color:
                      announcementCount > 0
                        ? "#f44336"
                        : activeTab === 0
                          ? "#1976d2"
                          : "rgba(0, 0, 0, 0.54)",
                    animation:
                      announcementCount > 0 ? "pulse 2s infinite" : "none",
                    "@keyframes pulse": {
                      "0%": {
                        transform: "scale(1)",
                        opacity: 1,
                      },
                      "50%": {
                        transform: "scale(1.1)",
                        opacity: 0.8,
                      },
                      "100%": {
                        transform: "scale(1)",
                        opacity: 1,
                      },
                    },
                  }}
                />
                <Typography
                  variant="button"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: "0.75rem",
                    letterSpacing: "0.5px",
                    color: activeTab === 0 ? "#1976d2" : "inherit",
                  }}
                >
                  Announcements
                </Typography>
              </Box>
            }
          />
          <Tab
            label={
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  position: "relative",
                }}
              >
                <NotificationsIcon
                  sx={{
                    fontSize: 18,
                    mr: 0.5,
                    color:
                      notificationCount > 0
                        ? "#f44336"
                        : activeTab === 1
                          ? "#1976d2"
                          : "rgba(0, 0, 0, 0.54)",
                    animation:
                      notificationCount > 0 ? "pulse 2s infinite" : "none",
                    "@keyframes pulse": {
                      "0%": {
                        transform: "scale(1)",
                        opacity: 1,
                      },
                      "50%": {
                        transform: "scale(1.1)",
                        opacity: 0.8,
                      },
                      "100%": {
                        transform: "scale(1)",
                        opacity: 1,
                      },
                    },
                  }}
                />
                <Typography
                  variant="button"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 600,
                    fontSize: "0.75rem",
                    letterSpacing: "0.5px",
                    color: activeTab === 1 ? "#1976d2" : "inherit",
                  }}
                >
                  Notifications
                </Typography>
              </Box>
            }
          />
        </Tabs>
      </Box>

      {filteredNotifications.length === 0 ? (
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography color="text.secondary">
            {activeTab === 0 ? "No new announcements" : "No new notifications"}
          </Typography>
        </Box>
      ) : (
        <Box>
          {renderNotificationGroup(groupedNotifications.today, "Today")}
          {renderNotificationGroup(groupedNotifications.yesterday, "Yesterday")}
          {renderNotificationGroup(groupedNotifications.thisWeek, "This Week")}
          {renderNotificationGroup(groupedNotifications.older, "Older")}
        </Box>
      )}
    </Menu>
  );
};

const Header = () => {
  const auth = useContext(AuthContext);
  const { notifications, markAsRead, markAllAsRead } =
    useContext(NotificationContext);
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [profileImage, setProfileImage] = useState(defaultUserImage);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [contactAnchorEl, setContactAnchorEl] = useState(null);
  const [pageTitle, setPageTitle] = useState("");
  const [lastActivity, setLastActivity] = useState(null);
  const [tokenExpiry, setTokenExpiry] = useState(null);
  const [manualStatus, setManualStatus] = useState("Available");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openFAQsModal, setOpenFAQsModal] = useState(false);
  const [shortcutsOpen, setShortcutsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);

  // Add permission state
  const [userPermissions, setUserPermissions] = useState({});
  const [permissionsLoaded, setPermissionsLoaded] = useState(false);

  // Permission checking function
  const hasPermission = (pageId, requiredLevel = "view") => {
    // Admin always has full access
    if (auth.role === "admin") {
      return true;
    }

    // Check permissions from our state
    const userAccessLevel = userPermissions[pageId];

    if (!userAccessLevel) {
      return false; // No permission defined
    }

    if (requiredLevel === "view") {
      // For view, either "view" or "edit" access is sufficient
      return userAccessLevel === "view" || userAccessLevel === "edit";
    } else if (requiredLevel === "edit") {
      // For edit, only "edit" access is sufficient
      return userAccessLevel === "edit";
    }

    return false;
  };

  // Fetch user permissions
  useEffect(() => {
    const fetchUserPermissions = async () => {
      if (auth && auth.user_id && auth.token) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${auth.user_id}/permissions`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            },
          );

          if (!response.ok) {
            throw new Error("Failed to fetch permissions");
          }

          const data = await response.json();

          // Convert permissions array to an easy-to-use object
          const permissionsMap = {};
          if (data && data.permissions && Array.isArray(data.permissions)) {
            data.permissions.forEach((permission) => {
              permissionsMap[permission.page_id] = permission.access_level;
            });
          }

          setUserPermissions(permissionsMap);
          setPermissionsLoaded(true);
        } catch (error) {
          console.error("Error fetching user permissions:", error);
          setPermissionsLoaded(true); // Set to true even on error to avoid infinite loading
        }
      } else {
        setPermissionsLoaded(true); // Set to true if no auth info available
      }
    };

    fetchUserPermissions();
  }, [auth.user_id, auth.token]);

  useEffect(() => {
    const fetchUsername = async (user_id) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${user_id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        );
        if (!response.ok) throw new Error("Failed to fetch username");
        const data = await response.json();
        return data.username;
      } catch (error) {
        console.error("Error fetching username:", error);
        return null;
      }
    };

    const setTitle = async () => {
      switch (location.pathname) {
        case "/landing-page":
          setPageTitle("");
          break;
        case "/leads/new":
          setPageTitle("Create Lead");
          break;
        case "/leads/uploadlist":
          setPageTitle("Upload List");
          break;
        case `/${auth.user_id}/leads`:
          setPageTitle("My Leads");
          break;
        case `/${auth.user_id}/uploads`:
          setPageTitle("My Uploads");
          break;
        case "/leadflow":
          setPageTitle("Lead Flow");
          break;
        case "/accounts/management":
          setPageTitle("Account Management");
          break;
        case "/zip-code-management":
          setPageTitle("Zip Code Routing");
          break;
        case "/queue-mapping":
          setPageTitle("CRM Queue Mapping");
          break;
        case "/connected-apps":
          setPageTitle("Connected Apps");
          break;
        case "/all-users":
          setPageTitle("User Management");
          break;
        case "/user-settings":
          setPageTitle("User Settings");
          break;
        case "/events":
          setPageTitle("Events");
          break;
        case "/campaigns/track":
          setPageTitle("Campaign Track");
          break;
        case "/audience-builder":
          setPageTitle("Audience Builder");
          break;
        case "/team-hub":
          setPageTitle("My Team");
          break;
        case "/unified-timeline":
          setPageTitle("Unified Timeline");
          break;
        case "/lead-pulse":
          setPageTitle("LeadPulse");
          break;
        case "/team-management":
          setPageTitle("Team Management");
          break;
        case "/team-hub/campaigns":
          setPageTitle("My Team - Campaigns");
          break;
        case "/team-hub/events":
          setPageTitle("My Team - Events");
          break;
        case "/team-hub/team-leads":
          setPageTitle("My Team - Leads");
          break;
        case "/privacy-policy":
          setPageTitle("Privacy Policy");
          break;
        case "/terms-and-conditions":
          setPageTitle("Terms & Conditions");
          break;
        case "/messages":
          setPageTitle("Messages");
          break;
        case "/broadcast":
          setPageTitle("Broadcast");
          break;
        case "/projects":
          setPageTitle("Projects");
          break;
        default:
          // Check if current path matches pattern for project tasks view
          const projectMatch = location.pathname.match(
            /^\/projects\/([^/]+)\/tasks$/,
          );
          if (projectMatch) {
            const project_id = projectMatch[1];
            try {
              // Fetch project details to get the name
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/projects/${project_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${auth.token}`,
                  },
                },
              );

              if (response.ok) {
                const projectData = await response.json();
                setPageTitle(`Projects - ${projectData.name}`);
              } else {
                setPageTitle("Project Details");
              }
            } catch (error) {
              console.error("Error fetching project name:", error);
              setPageTitle("Project Details");
            }
          } else {
            // Check for user leads match (keep your existing code)
            const leadsMatch = location.pathname.match(/^\/([^/]+)\/leads$/);
            if (leadsMatch) {
              const user_id = leadsMatch[1];
              if (user_id === auth.user_id) {
                setPageTitle("My Leads");
              } else {
                const username = await fetchUsername(user_id);
                if (username) {
                  setPageTitle(`${username} - Leads`);
                } else {
                  setPageTitle("User Leads");
                }
              }
            } else {
              setPageTitle("Dashboard");
            }
          }
          break;
      }
    };

    setTitle();
  }, [location.pathname, auth.user_id, auth.token]);

  useEffect(() => {
    if (auth.isAuthLoaded && !auth.isLoggedIn) {
      history.push("/");
    }
  }, [auth.isAuthLoaded, auth.isLoggedIn, history]);

  useEffect(() => {
    if (auth.isAuthReady && auth.user_id) {
      const fetchProfileImage = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${auth.user_id}/profile-image`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            },
          );
          if (!response.ok) {
            throw new Error("Failed to fetch profile image");
          }
          const data = await response.json();
          setProfileImage(data.image_url || defaultUserImage);
        } catch (error) {
          console.error("Error fetching profile image:", error);
        }
      };

      fetchProfileImage();
    }
  }, [auth.isAuthReady, auth.user_id, auth.token]);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${auth.user_id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error("Failed to fetch user status");
        }
        const data = await response.json();
        setLastActivity(data.last_activity);
        setTokenExpiry(data.token_expiry);
        setManualStatus(data.manual_status || "Available");
      } catch (error) {
        console.error("Error fetching user status:", error);
      }
    };

    if (auth.user_id && auth.token) {
      fetchUserStatus();
    }
  }, [auth.user_id, auth.token]);

  useEffect(() => {
    const fetchUnreadMessagesCount = async () => {
      if (auth && auth.user_id && auth.token) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/messages/unread-count`,
            {
              headers: {
                Authorization: `Bearer ${auth.token}`,
              },
            },
          );

          if (!response.ok) {
            throw new Error("Failed to fetch unread messages count");
          }

          const data = await response.json();
          setUnreadMessageCount(data.unread_count);
        } catch (error) {
          console.error("Error fetching unread messages count:", error);
        }
      }
    };

    fetchUnreadMessagesCount();

    // Refresh the count every 30 seconds
    const intervalId = setInterval(fetchUnreadMessagesCount, 30000);

    return () => clearInterval(intervalId);
  }, [auth.user_id, auth.token]);

  const handleStatusChange = async (newStatus) => {
    try {
      setManualStatus(newStatus);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${auth.user_id}/status`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${auth.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newStatus),
        },
      );
      if (!response.ok) throw new Error("Failed to update status");
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const currentTime = new Date();
  let status = "Offline";
  if (tokenExpiry && new Date(tokenExpiry) > currentTime) {
    if (lastActivity) {
      const lastActivityTime = new Date(lastActivity);
      const diffMs = currentTime - lastActivityTime;
      const diffMins = diffMs / (1000 * 60);
      if (diffMins < 15) {
        status = "Available";
      } else {
        status = "Away";
      }
    } else {
      status = "Away";
    }
  }

  const statusColors = {
    Available: "#44b700",
    Busy: "#ff0000",
    Away: "#fbc02d",
    Offline: "#6e6e6e",
  };

  const combinedStatus = manualStatus || status;

  const badgeColor = statusColors[combinedStatus];

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePagesMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleContactMenu = (event) => {
    setContactAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationsAnchorEl(null);
    setMenuAnchorEl(null);
    setContactAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    history.push("/");
    handleClose();
  };

  const handleUserSettings = () => {
    history.push("/user-settings");
    handleClose();
  };

  const handleContactUs = () => {
    window.location.href =
      "mailto:leadfeedteam@comcast.com?subject=leadfeed%20Feedback";
    handleClose();
  };

  const isActive = (path) => currentPath === path;

  const handleOpenFAQsModal = () => setOpenFAQsModal(true);
  const handleCloseFAQsModal = () => setOpenFAQsModal(false);
  const handleCloseKeyboardShortcuts = () => setShortcutsOpen(false);
  const handleChangeTab = (event, newValue) => setActiveTab(newValue);

  const menuItems = [
    // Quick Actions (actions)
    {
      section: "Quick Actions",
      items: [
        {
          path: "/leads/new",
          label: "Create New Lead",
          pageId: "leads-new",
          permission: "edit",
        },
        {
          path: "/leads/uploadlist",
          label: "Upload Leads",
          pageId: "leads-upload",
          permission: "edit",
        },
      ],
    },
    // My Activity (activity)
    {
      section: "My Activity",
      items: [
        {
          path: `/${auth.user_id}/leads`,
          label: "My Leads",
          pageId: "user-leads",
          permission: "view",
        },
        {
          path: `/${auth.user_id}/uploads`,
          label: "My Uploads",
          pageId: "user-uploads",
          permission: "view",
        },
        {
          path: "/team-hub",
          label: "My Team",
          pageId: "team-hub",
          permission: "view",
        },
        {
          path: "/messages",
          label: "Messages",
          pageId: "messages",
          permission: "view",
        },
        {
          path: "/projects",
          label: "Projects",
          pageId: "projects",
          permission: "view",
        },
      ],
    },
    // Marketing Operations (marketing)
    {
      section: "Marketing Operations",
      items: [
        {
          path: "/events",
          label: "Events",
          pageId: "events",
          permission: "view",
        },
        {
          path: "/audience-builder",
          label: "Audience Builder",
          pageId: "audience-builder",
          permission: "view",
        },
        {
          path: "/campaigns/track",
          label: "Campaign Track",
          pageId: "campaigns",
          permission: "view",
        },
        {
          path: "/unified-timeline",
          label: "Unified Timeline",
          pageId: "unified-timeline",
          permission: "view",
        },
        {
          path: "/lead-pulse",
          label: "LeadPulse",
          pageId: "lead-pulse",
          permission: "view",
        },
      ],
    },
    // System Configuration (system)
    {
      section: "System Configuration",
      items: [
        {
          path: "/accounts/management",
          label: "Account Management",
          pageId: "accounts",
          permission: "view",
        },
        {
          path: "/zip-code-management",
          label: "Zip Code Routing",
          pageId: "zip-code-management",
          permission: "view",
        },
        {
          path: "/queue-mapping",
          label: "CRM Queue Mapping",
          pageId: "queue-mapping",
          permission: "view",
        },
        {
          path: "/connected-apps",
          label: "Connected Apps",
          pageId: "connected-apps",
          permission: "view",
        },
        {
          path: "/leadflow",
          label: "Lead Flow",
          pageId: "leadflow",
          permission: "view",
        },
      ],
    },
    // Administration (admin)
    {
      section: "Administration",
      items: [
        {
          path: "/all-users",
          label: "User Management",
          pageId: "all-users",
          permission: "view",
        },
        {
          path: "/team-management",
          label: "Team Management",
          pageId: "team-management",
          permission: "view",
        },
        {
          path: "/broadcast",
          label: "Broadcast",
          pageId: "broadcast",
          permission: "view",
        },
      ],
    },
  ];

  // Render menu sections and items based on permissions
  const renderMenuSections = () => {
    return menuItems.map((section, sectionIndex) => {
      // Filter the items in this section based on user permissions
      const visibleItems = section.items.filter((item) =>
        hasPermission(item.pageId, item.permission),
      );

      // Only show section if it has visible items
      if (visibleItems.length === 0) {
        return null;
      }

      return (
        <React.Fragment key={sectionIndex}>
          <ListSubheader>{section.section}</ListSubheader>
          {visibleItems.map((item, itemIndex) => (
            <MenuItem
              key={`${sectionIndex}-${itemIndex}`}
              component={NavLink}
              to={item.path}
              onClick={handleClose}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: isActive(item.path)
                  ? "rgba(0, 0, 0, 0.08)"
                  : "inherit",
              }}
            >
              <ListItemText primary={item.label} />
              {isActive(item.path) && <CheckIcon />}
            </MenuItem>
          ))}
        </React.Fragment>
      );
    });
  };

  const faqs = {
    createLead: [
      {
        question: "What fields are required to create a lead?",
        answer:
          "Required fields typically include company name, contact first name, contact last name, and contact email. Additional fields like address or phone number can also be included.",
      },
      {
        question: "Can I save a partially completed lead form?",
        answer:
          "Yes, the lead form automatically saves your progress locally, so you can return later to complete and submit it.",
      },
      {
        question: "How do I specify a product of interest for a lead?",
        answer:
          "In the 'Products of Interest' field, select one or more products from the dropdown list before submitting the form.",
      },
    ],
    uploadList: [
      {
        question: "How do I upload a list of leads?",
        answer:
          "Navigate to the 'Upload Leads' section, select your file, and upload it. Ensure the file is in a supported format like CSV or Excel.",
      },
      {
        question:
          "What should I do if my file headers don't match the expected format?",
        answer:
          "The platform provides an interactive mapping tool that allows you to match your file headers to the expected format during the upload process.",
      },
      {
        question: "Can I upload files in chunks to handle large datasets?",
        answer:
          "Yes, the system automatically divides large files into smaller chunks during upload to ensure smooth processing.",
      },
      {
        question: "Do I need to map fields myself?",
        answer:
          "The platform automatically attempts to map your file headers to the required fields. However, if the headers do not match, you will need to map them manually using the interactive mapping tool provided during the upload process.",
      },
      {
        question: "How do I ensure my uploaded file is processed correctly?",
        answer:
          "Verify your file headers during the mapping step and confirm all mandatory fields are properly mapped before submission.",
      },
    ],
    myUploads: [
      {
        question: "Where can I view my uploads?",
        answer:
          "All uploaded files can be viewed in the 'My Uploads' section, along with their enrichment status and download options.",
      },
      {
        question: "How do I check the enrichment status of my files?",
        answer:
          "The enrichment status is displayed next to each file in the 'My Uploads' section. Click on the file to view more details.",
      },
    ],
    myLeads: [
      {
        question: "What is the 'My Leads' section?",
        answer:
          "The 'My Leads' section provides access to all your saved leads, enabling you to manage and track them efficiently.",
      },
    ],
  };

  const tabData = [
    { label: "Create Lead", content: faqs.createLead },
    { label: "Upload List", content: faqs.uploadList },
    { label: "My Uploads", content: faqs.myUploads },
    { label: "My Leads", content: faqs.myLeads },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "#1976d2",
        height: "68px",
        boxShadow: "none",
        zIndex: (theme) => theme.zIndex.drawer + 2,
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        "@media (max-width: 600px)": {
          backgroundColor: "#1976d2",
          height: "68px",
        },
        transform: "translateZ(0)",
      }}
    >
      <Toolbar
        sx={{
          height: "68px",
          position: "relative",
          padding: {
            xs: "0 16px",
            sm: "0 24px",
          },
          "@media (max-width: 600px)": {
            backgroundColor: "#1976d2",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/landing-page"
            style={{ textDecoration: "none", color: "white" }}
          >
            <img
              src={logo}
              alt="CB Logo"
              style={{ height: "40px", marginRight: "10px" }}
            />
          </Link>
        </Box>
        <Typography
          variant="h6"
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            fontSize: "1.4rem",
            fontWeight: "bold",
            display: isMobile ? "none" : "block",
          }}
        >
          {pageTitle}
        </Typography>

        {/* Right-aligned: User Actions */}
        <Box
          sx={{
            marginLeft: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: 2,
            padding: "0 8px",
            minWidth: "220px",
          }}
        >
          {/* Pages Menu */}
          <Tooltip title="Pages">
            <IconButton
              edge="start"
              aria-label="apps"
              aria-controls="menu-pages"
              aria-haspopup="true"
              onClick={handlePagesMenu}
              color="inherit"
              sx={{
                fontSize: "1.5rem",
                padding: "8px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <AppsIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-pages"
            anchorEl={menuAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(menuAnchorEl)}
            onClose={handleClose}
            sx={{ marginTop: "5px" }}
          >
            {auth.isLoggedIn && renderMenuSections()}
          </Menu>

          {/* Support Menu */}
          <Tooltip title="Support">
            <IconButton
              edge="start"
              aria-label="contact support"
              aria-controls="menu-contact"
              aria-haspopup="true"
              onClick={handleContactMenu}
              color="inherit"
              sx={{
                fontSize: "1.5rem",
                padding: "8px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-contact"
            anchorEl={contactAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(contactAnchorEl)}
            onClose={handleClose}
            sx={{ marginTop: "5px" }}
          >
            <MenuItem onClick={handleContactUs}>
              <ListItemIcon>
                <MailIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Contact Us" />
            </MenuItem>
            <MenuItem onClick={() => setShortcutsOpen(true)}>
              <ListItemIcon>
                <LightbulbIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Keyboard Shortcuts" />
            </MenuItem>
            <MenuItem onClick={handleOpenFAQsModal}>
              <ListItemIcon>
                <FAQIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="FAQs" />
            </MenuItem>
          </Menu>

          {/* Messages */}
          {hasPermission("messages", "view") && (
            <Badge
              badgeContent={unreadMessageCount}
              color="error"
              sx={{
                padding: 0,
                "& .MuiBadge-badge": {
                  right: 2,
                  top: 2,
                  minWidth: "18px",
                  height: "18px",
                },
              }}
            >
              <IconButton
                edge="end"
                aria-label="messages"
                color="inherit"
                onClick={() => history.push("/messages")}
                sx={{
                  padding: "8px",
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                  },
                }}
              >
                <ChatIcon sx={{ fontSize: "1.2rem" }} />
              </IconButton>
            </Badge>
          )}

          {/* Notifications */}
          <Badge
            badgeContent={notifications.length}
            color="error"
            sx={{
              padding: 0,
              "& .MuiBadge-badge": {
                right: 2,
                top: 2,
                minWidth: "18px",
                height: "18px",
              },
            }}
          >
            <IconButton
              edge="end"
              aria-label="notifications"
              color="inherit"
              onClick={(event) => setNotificationsAnchorEl(event.currentTarget)}
              sx={{
                padding: "8px",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <NotificationsIcon sx={{ fontSize: "1.2rem" }} />
            </IconButton>
          </Badge>
          <NotificationsMenu
            anchorEl={notificationsAnchorEl}
            open={Boolean(notificationsAnchorEl)}
            onClose={() => setNotificationsAnchorEl(null)}
            notifications={notifications}
            onMarkAsRead={markAsRead}
            onMarkAllAsRead={() => {
              markAllAsRead();
              setNotificationsAnchorEl(null);
            }}
            auth={auth}
          />
          {/* User Menu */}
          <Box
            sx={{
              position: "relative",
              ml: 2,
              padding: "4px",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              transition: "background-color 200ms",
            }}
          >
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              sx={{ fontSize: "1.5rem" }}
            >
              <Tooltip title={combinedStatus}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  badgeColor={badgeColor}
                  status={status}
                >
                  <Avatar
                    src={profileImage}
                    sx={{
                      width: 45,
                      height: 45,
                    }}
                  />
                </StyledBadge>
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 3,
            sx: {
              mt: 1.5,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
              "& .MuiMenuItem-root": {
                px: 2,
                py: 1.5,
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              },
              "& .MuiDivider-root": {
                my: 1,
              },
            },
          }}
        >
          <Box
            sx={{
              px: 2,
              py: 1,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: "1.25rem",
                fontWeight: 600,
                color: "rgb(37, 47, 63)",
              }}
            >
              Status
            </Typography>
          </Box>
          <MenuItem
            onClick={() => handleStatusChange("Available")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Circle
              fill={statusColors.Available}
              color={statusColors.Available}
              size={12}
            />
            <ListItemText primary="Available" />
            {manualStatus === "Available" && (
              <Check size={18} className="ml-auto" />
            )}
          </MenuItem>

          <MenuItem
            onClick={() => handleStatusChange("Busy")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Circle
              fill={statusColors.Busy}
              color={statusColors.Busy}
              size={12}
            />
            <ListItemText primary="Busy" />
            {manualStatus === "Busy" && <Check size={18} className="ml-auto" />}
          </MenuItem>

          <MenuItem
            onClick={() => handleStatusChange("Away")}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Circle
              fill={statusColors.Away}
              color={statusColors.Away}
              size={12}
            />
            <ListItemText primary="Appear Away" />
            {manualStatus === "Away" && <Check size={18} className="ml-auto" />}
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={handleUserSettings}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Settings size={18} />
            <ListItemText primary="User Settings" />
          </MenuItem>

          <MenuItem
            onClick={handleLogout}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <LogOut size={18} />
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </Toolbar>
      {/* FAQs Modal */}
      <Modal
        open={openFAQsModal}
        onClose={handleCloseFAQsModal}
        closeAfterTransition
      >
        <Fade in={openFAQsModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "16px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              width: "90%",
              maxWidth: 500,
              maxHeight: "70vh",
              overflowY: "auto",
              p: 3,
            }}
          >
            {/* Centered Header */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 700,
                  fontFamily: "Roboto, sans-serif",
                  letterSpacing: "0.5px",
                  color: theme.palette.primary.main,
                  textAlign: "center",
                }}
              >
                Frequently Asked Questions
              </Typography>
              <IconButton
                onClick={handleCloseFAQsModal}
                size="small"
                sx={{ position: "absolute", right: 16 }}
              >
                <CloseIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </Box>

            {/* Improved Tabs */}
            <Tabs
              value={activeTab}
              onChange={handleChangeTab}
              centered
              sx={{
                mb: 2,
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "1rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#424242",
                  padding: "8px 16px",
                  color: "#757575",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                },
                "& .Mui-selected": {
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              {tabData.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>

            {/* Accordion Content */}
            {tabData[activeTab].content.map((faq, index) => (
              <Accordion
                key={index}
                sx={{
                  mb: 1,
                  border: "1px solid #e0e0e0",
                  borderRadius: "8px",
                  overflow: "hidden",
                  "&:hover": {
                    borderColor: "#bdbdbd",
                  },
                  boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.05)",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: "#757575", fontSize: 20 }} />
                  }
                  sx={{
                    backgroundColor: "#fafafa",
                    "&.Mui-expanded": { backgroundColor: "#f5f5f5" },
                    padding: "6px 12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.9rem",
                      color: "#424242",
                    }}
                  >
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "6px 12px" }}>
                  <Typography sx={{ color: "#616161", fontSize: "0.85rem" }}>
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Fade>
      </Modal>

      {/* Keyboard Shortcuts Modal */}
      <Modal
        open={shortcutsOpen}
        onClose={handleCloseKeyboardShortcuts}
        closeAfterTransition
      >
        <Fade in={shortcutsOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "white",
              borderRadius: "16px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
              width: "90%",
              maxWidth: 500,
              p: 3,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 700,
                fontFamily: "Roboto, sans-serif",
                letterSpacing: "0.5px",
                color: theme.palette.primary.main,
                textAlign: "center",
                mb: 2,
              }}
            >
              Keyboard Shortcuts
            </Typography>

            {/* Keyboard Shortcuts Modal Tabs */}
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              centered
              sx={{
                mb: 2,
                "& .MuiTab-root": {
                  textTransform: "none",
                  fontWeight: 600,
                },
                "& .MuiTabs-indicator": {
                  backgroundColor: "#FFA726",
                },
              }}
            >
              <Tab label="Quick Actions" />
              <Tab label="System Configuration" />
              <Tab label="General" />
            </Tabs>

            <Divider sx={{ mb: 2 }} />

            {/* Quick Actions tab */}
            {activeTab === 0 && (
              <Box>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + C:</strong> Create New Lead
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + U:</strong> Upload List
                </Typography>
              </Box>
            )}

            {/* System Configuration tab */}
            {activeTab === 1 && (
              <Box>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + M:</strong> Account Management
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + Z:</strong> Zip Code Management
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + Q:</strong> CRM Queue Mapping
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + N:</strong> Connected Apps
                </Typography>
              </Box>
            )}

            {activeTab === 2 && (
              <Box>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + L:</strong> Logout
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + I:</strong> User Settings
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Alt + B:</strong> User Management
                </Typography>
              </Box>
            )}

            <Divider sx={{ mt: 2, mb: 2 }} />

            <IconButton
              onClick={handleCloseKeyboardShortcuts}
              size="small"
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                color: "#757575",
              }}
            >
              <CloseIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
        </Fade>
      </Modal>
    </AppBar>
  );
};

export default Header;
