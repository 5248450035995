import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FeedbackModal from './FeedbackModal';
import './MainFooter.css';
import heartImage from './heart.png';

const MainFooter = (props) => {
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  
  const openFeedbackModal = () => {
    setIsFeedbackModalOpen(true);
  };
  
  const closeFeedbackModal = () => {
    setIsFeedbackModalOpen(false);
  };
  
  return (
    <footer className='main-footer'>
      <div className='footer-left'>
        <button className='feedback-button' onClick={openFeedbackModal}>
          Feedback
        </button>
        <Link to="/privacy-policy" className='legal-link'>
          Privacy Policy
        </Link>
        <Link to="/terms-and-conditions" className='legal-link'>
          Terms & Conditions
        </Link>
      </div>
      <div className='footer-right'>
        Designed with&nbsp;
        <img src={heartImage} alt='love' width='15' height='15' />
        &nbsp;in Denver
      </div>
      {/* Feedback Modal */}
      {isFeedbackModalOpen && (
        <FeedbackModal onClose={closeFeedbackModal} />
      )}
    </footer>
  );
};

export default MainFooter;
