import React, { useContext, useEffect, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import MainNavigation from "./shared/components/Navigation/MainNavigation";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";
import { AuthContext, AuthContextProvider } from "./shared/context/auth-context";
import { NotificationProvider } from "./shared/context/NotificationContext";
import TokenExpirationHandler from './shared/components/TokenExpirationHandler';
import { CssBaseline, Box } from "@mui/material";
import "./App.css";
import { renderRoutes } from './routes';

const LoadingFallback = () => (
  <div className="center" style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    height: '100vh' 
  }}>
    <LoadingSpinner />
  </div>
);

const App = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const pathsWithoutNav = ["/auth", "/reset-password", "/join-team"];
  const history = useHistory();

  const handleLogout = () => {
    auth.logout();
    history.push('/');
  };
  
  const navigateToNewLead = () => history.push("/leads/new");
  const navigateToUserLeads = () => history.push(`/${auth.user_id}/leads`);
  const navigateToUploadList = () => history.push("/leads/uploadlist");
  const navigateToAccountManagement = () => history.push("/accounts/management");
  const navigateToLeadFlow = () => history.push("/leadflow");
  const navigateToConnectedApps = () => history.push("/connected-apps");
  const navigateToZipCodeManagement = () => history.push("/zip-code-management");
  const navigateToQueueMapping = () => history.push("/queue-mapping");
  const navigateToUserUploads = () => history.push(`/${auth.user_id}/uploads`);
  const navigateToAllUsers = () => history.push("/all-users");
  const navigateToUserSettings = () => history.push("/user-settings");

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!auth.token) return;
      
      if (event.altKey && event.key === 'C' || event.altKey && event.key === 'c') {
        navigateToNewLead();
      }
      if (event.altKey && event.key === 'u' || event.altKey && event.key === 'U') {
        navigateToUploadList();
      }
      if (event.altKey && event.key === 'L' || event.altKey && event.key === 'l') {
        handleLogout();
      }
      if (event.altKey && event.key === 'i' || event.altKey && event.key === 'I') {
        navigateToUserSettings();
      }
      if (event.altKey && event.key === 'm' || event.altKey && event.key === 'M') {
        navigateToAccountManagement()
      }
      if (event.altKey && event.key === 'n' || event.altKey && event.key === 'N') {
        navigateToConnectedApps()
      }
      if (event.altKey && event.key === 'z' || event.altKey && event.key === 'Z') {
        navigateToZipCodeManagement()
      }
      if (event.altKey && event.key === 'b' || event.altKey && event.key === 'B') {
        navigateToAllUsers()
      }
      if (event.altKey && event.key === 'q' || event.altKey && event.key === 'Q') {
        navigateToQueueMapping()
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [auth.token, history]);

  // If auth isn't ready yet, show loading spinner
  if (!auth.isAuthReady) {
    return <LoadingFallback />;
  }

  // Check if user path is valid to avoid issues with undefined user_id
  const isUserPathValid = auth.token && auth.user_id;

  return (
    <>
      {!pathsWithoutNav.includes(location.pathname) && auth.token && (
        <MainNavigation />
      )}
      <CssBaseline />
      <Box className="main-content">
        <Box className="scrollable-container">
          <Switch>
            {/* Only render routes if auth is ready */}
            {auth.isAuthReady && renderRoutes(auth)}
            <Redirect to={auth.token ? "/landing-page" : "/auth"} />
          </Switch>
        </Box>
      </Box>
    </>
  );
};

// Wrap the App with necessary providers
const SafeApp = () => {
  return (
    <Suspense fallback={<LoadingFallback />}>
      <App />
    </Suspense>
  );
};

const AppWithRouter = () => (
  <Router>
    <AuthContextProvider>
      <NotificationProvider>
        <TokenExpirationHandler />
        <SafeApp />
      </NotificationProvider>
    </AuthContextProvider>
  </Router>
);

export default AppWithRouter;
