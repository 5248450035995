import React, { lazy, Suspense, useState, useEffect, useContext } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { AuthContext } from "./shared/context/auth-context";

const LoadingSpinner = lazy(
  () => import("./shared/components/UIElements/LoadingSpinner"),
);
const AccessDenied = lazy(
  () => import("./shared/components/UIElements/AccessDenied"),
);

export const PAGE_DEFINITIONS = [
  {
    id: "landing-page",
    name: "Dashboard",
    description: "Main dashboard and system overview",
  },
  {
    id: "all-users",
    name: "User Management",
    description: "Manage user accounts and permissions",
  },
  {
    id: "user-leads",
    name: "User Leads",
    description: "View and manage your leads",
  },
  {
    id: "user-uploads",
    name: "User Uploads",
    description: "View your uploaded files and lists",
  },
  {
    id: "leads-new",
    name: "Create Lead",
    description: "Create new individual leads",
  },
  {
    id: "leads-upload",
    name: "Upload Leads",
    description: "Bulk upload lead lists",
  },
  {
    id: "user-settings",
    name: "User Settings",
    description: "Manage your account settings",
  },
  {
    id: "accounts",
    name: "Account Management",
    description: "Manage system accounts and integration",
  },
  {
    id: "zip-code-management",
    name: "Zip Code Management",
    description: "Configure geographical targeting",
  },
  {
    id: "queue-mapping",
    name: "Queue Mapping",
    description: "Configure lead routing and assignment",
  },
  {
    id: "connected-apps",
    name: "Connected Apps",
    description: "Manage external application connections",
  },
  {
    id: "leadflow",
    name: "Lead Flow",
    description: "Reporting and analytics dashboard",
  },
  {
    id: "audience-builder",
    name: "Audience Builder",
    description: "Create and manage audience segments",
  },
  {
    id: "events",
    name: "Events",
    description: "Manage system events and notifications",
  },
  {
    id: "campaigns",
    name: "Campaign Tracking",
    description: "Track marketing campaign performance",
  },
  {
    id: "team-hub",
    name: "Team Hub",
    description: "Team collaboration and management tools",
  },
  {
    id: "unified-timeline",
    name: "Unified Timeline",
    description: "View chronological system activity",
  },
  {
    id: "lead-pulse",
    name: "Lead Pulse",
    description: "Real-time lead activity monitoring",
  },
  { 
  id: "team-management", 
  name: "Team Management", 
  description: "Create and manage teams and memberships" 
  },
    { 
    id: "messages", 
    name: "Messages", 
    description: "Send and receive messages with other team members" 
  },
  { 
    id: "projects", 
    name: "Projects", 
    description: "Manage multiple projects and organize your tasks efficiently" 
  },
  { 
    id: "project-tasks", 
    name: "Project Tasks", 
    description: "View and manage tasks within a specific project" 
  },
    { 
    id: "broadcast", 
    name: "Broadcast", 
    description: "Send notifications to specific users or broadcast announcements to all users in the system" 
  },
];

const LoadingFallback = () => (
  <div
    className="center"
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <LoadingSpinner />
  </div>
);

const PermissionAwareRoute = ({
  component: Component,
  pageId,
  requiredPermission = "view",
  ...rest
}) => {
  const auth = useContext(AuthContext);
  const [hasPermission, setHasPermission] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setHasPermission(null);
    setError(null);
    
    if (!auth.token || !auth.isAuthReady) {
      setHasPermission(false);
      return;
    }

    // Admin always has full access
    if (auth.role === "admin") {
      setHasPermission(true);
      return;
    }

    const checkPermission = async () => {
      try {
        if (!auth.user_id || !auth.token) {
          setHasPermission(false);
          return;
        }
        
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/check-permission/${auth.user_id}/${pageId}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          },
        );

        if (!response.ok) {
          console.error(`Failed to check permissions: ${response.status}`);
          setHasPermission(false);
          return;
        }

        const data = await response.json();

        setHasPermission(
          data.access_level === "edit" ||
            (data.access_level === "view" && requiredPermission === "view"),
        );
      } catch (err) {
        console.error("Error checking permissions:", err);
        setError(err.message || "Failed to check permissions");
        setHasPermission(false);
      }
    };

    checkPermission();
  }, [auth.token, auth.user_id, auth.role, auth.isAuthReady, pageId, requiredPermission]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.token) {
          return (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: props.location },
              }}
            />
          );
        }

        if (hasPermission === null && auth.isAuthReady) {
          return <LoadingFallback />;
        }

        if (hasPermission === false) {
          return (
            <Suspense fallback={<LoadingFallback />}>
              <AccessDenied />
            </Suspense>
          );
        }

        if (hasPermission === true) {
          return (
            <Suspense fallback={<LoadingFallback />}>
              <Component {...props} />
            </Suspense>
          );
        }

        return <LoadingFallback />;
      }}
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!auth.token) {
          return (
            <Redirect
              to={{
                pathname: "/auth",
                state: { from: props.location },
              }}
            />
          );
        }

        if (auth.role !== "admin") {
          return (
            <Suspense fallback={<LoadingFallback />}>
              <AccessDenied />
            </Suspense>
          );
        }

        return (
          <Suspense fallback={<LoadingFallback />}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
};

const PublicRoute = ({ component: Component, restricted = false, ...rest }) => {
  const auth = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth.token && restricted) {
          return <Redirect to="/landing-page" />;
        }

        return (
          <Suspense fallback={<LoadingFallback />}>
            <Component {...props} />
          </Suspense>
        );
      }}
    />
  );
};

const routes = [
  // Public routes
  {
    path: "/privacy-policy",
    component: lazy(() => import("./shared/components/PrivacyPolicy")),
    exact: true,
    type: "public",
    restricted: false,
  },
  {
    path: "/terms-and-conditions",
    component: lazy(() => import("./shared/components/TermsAndConditions")),
    exact: true,
    type: "public",
    restricted: false,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("./login/pages/ResetPassword")),
    exact: false,
    type: "public",
  },
  {
    path: "/join-team",
    component: lazy(() => import("./teamhub/pages/JoinTeam")),
    exact: false,
    type: "public",
  },
  {
    path: "/",
    component: lazy(() => import("./login/pages/Login")),
    exact: true,
    type: "public",
    restricted: true,
  },
  {
    path: "/auth",
    component: lazy(() => import("./login/pages/Login")),
    exact: false,
    type: "public",
    restricted: true,
  },

  // Permission-based routes
  {
    path: "/all-users",
    component: lazy(() => import("./user/pages/Users")),
    exact: true,
    type: "permission",
    pageId: "all-users",
    permission: "view",
  },
  {
    path: "/:user_id/uploads",
    component: lazy(() => import("./uploads/pages/UserUploads")),
    exact: true,
    type: "permission",
    pageId: "user-uploads",
    permission: "view",
  },
  {
    path: "/:user_id/leads",
    component: lazy(() => import("./leads/pages/UserLeads")),
    exact: true,
    type: "permission",
    pageId: "user-leads",
    permission: "view",
  },
  {
    path: "/landing-page",
    component: lazy(() => import("./landing_page/LandingPage")),
    exact: true,
    type: "permission",
    pageId: "landing-page",
    permission: "view",
  },
  {
    path: "/leads/new",
    component: lazy(() => import("./leads/pages/NewLead")),
    exact: true,
    type: "permission",
    pageId: "leads-new",
    permission: "edit",
  },
  {
    path: "/leads/uploadlist",
    component: lazy(() => import("./leads/pages/UploadList")),
    exact: true,
    type: "permission",
    pageId: "leads-upload",
    permission: "edit",
  },
  {
    path: "/user-settings",
    component: lazy(() => import("./user/pages/UserSettings")),
    exact: true,
    type: "permission",
    pageId: "user-settings",
    permission: "view",
  },
  {
    path: "/accounts/management",
    component: lazy(() => import("./accounts/pages/AccountManagement")),
    exact: true,
    type: "permission",
    pageId: "accounts",
    permission: "view",
  },
  {
    path: "/zip-code-management",
    component: lazy(() => import("./zip_codes/pages/ZipCodeManagement")),
    exact: true,
    type: "permission",
    pageId: "zip-code-management",
    permission: "view",
  },
  {
    path: "/queue-mapping",
    component: lazy(() => import("./queue_mapping/pages/QueueMapping")),
    exact: true,
    type: "permission",
    pageId: "queue-mapping",
    permission: "view",
  },
  {
    path: "/connected-apps",
    component: lazy(() => import("./user/pages/ConnectedApps")),
    exact: true,
    type: "permission",
    pageId: "connected-apps",
    permission: "view",
  },
  {
    path: "/leadflow",
    component: lazy(() => import("./reporting/ReportingDashboard")),
    exact: true,
    type: "permission",
    pageId: "leadflow",
    permission: "view",
  },
  {
    path: "/audience-builder",
    component: lazy(() => import("./leads/pages/AudienceBuilderWrapper")),
    exact: true,
    type: "permission",
    pageId: "audience-builder",
    permission: "view",
  },
  {
    path: "/events",
    component: lazy(() => import("./events/pages/Events")),
    exact: true,
    type: "permission",
    pageId: "events",
    permission: "view",
  },
  {
    path: "/campaigns/track",
    component: lazy(() => import("./campaigns/pages/Campaigns")),
    exact: true,
    type: "permission",
    pageId: "campaigns",
    permission: "view",
  },
  {
    path: "/team-hub/:tab?",
    component: lazy(() => import("./teamhub/pages/TeamHub")),
    exact: true,
    type: "permission",
    pageId: "team-hub",
    permission: "view",
  },
  {
    path: "/unified-timeline",
    component: lazy(() => import("./unified_timeline/pages/UnifiedTimeline")),
    exact: true,
    type: "permission",
    pageId: "unified-timeline",
    permission: "view",
  },
  {
    path: "/lead-pulse",
    component: lazy(() => import("./lead_pulse/pages/LeadPulse")),
    exact: true,
    type: "permission",
    pageId: "lead-pulse",
    permission: "view",
  },
  {
  path: "/team-management",
  component: lazy(() => import("./teams/pages/Teams")),
  exact: true,
  type: "permission",
  pageId: "team-management",
  permission: "view",
  },
  {
  path: "/messages",
  component: lazy(() => import("./messages/pages/Messages")),
  exact: true,
  type: "permission",
  pageId: "messages",
  permission: "view",
  },
  {
  path: "/projects",
  component: lazy(() => import("./projects/pages/Projects")),
  exact: true,
  type: "permission",
  pageId: "projects",
  permission: "view",
  },
  {
  path: "/projects/:projectId/tasks",
  component: lazy(() => import("./projects/pages/ProjectTasks")),
  exact: true,
  type: "permission",
  pageId: "project-tasks",
  permission: "view",
  },
  {
  path: "/broadcast",
  component: lazy(() => import("./notifications/pages/Notifications")),
  exact: true,
  type: "permission",
  pageId: "broadcast",
  permission: "view",
  },
];

export const renderRoutes = (auth) => {
  if (!auth.isAuthReady) {
    return null;
  }

  return routes.map((route, index) => {
    switch (route.type) {
      case "public":
        return (
          <PublicRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            restricted={route.restricted || false}
          />
        );
      case "permission":
        return (
          <PermissionAwareRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            pageId={route.pageId}
            requiredPermission={route.permission || "view"}
          />
        );
      case "admin":
        return (
          <AdminRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        );
      default:
        return null;
    }
  });
};
